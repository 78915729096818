import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {observer} from "mobx-react";
import PropTypes from 'prop-types';
import {CSSTransition} from "react-transition-group";

import styles from './styles/Header.module.scss';

import NavigationPrimary from './NavigationPrimary';
import NavigationSecondary from './NavigationSecondary';
import {StoreContext} from "../../stores/StoreLoader";
import dynamic from "next/dynamic";
import {primaryNavHeight} from "../../utils/Settings";

const HeroContainer = dynamic(() => import("./HeroContainer"));

const heroContainerClassName = classNames({
    [styles.heroContainer]: true,
});

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const Header = observer((props) => {
    const {styleStore, interfaceStore, organizationStore} = useContext(StoreContext);

    const alwaysShowHeader = organizationStore.currentOrganization.json_data.settings?.appearance?.alwaysShowHeader;
    const [visible, setVisible] = useState((!props.heroIsEnabled || alwaysShowHeader) && !props.isWelcomePage);
    const heroRef = useRef(null);
    const secondaryNavRef = useRef(null);

    useEffect(() => {
        // this is running in a useEffect because scrollPosition is always 0 on SSR so otherwise a reload while scrolled
        // down doesn't hydrate with the correct classNames and it doesn't update
        if (alwaysShowHeader || interfaceStore.scrollPosition > primaryNavHeight || !props.displayHero) {
            setVisible(true);
        } else if (visible) {
            setVisible(false);
        }

    }, [interfaceStore.scrollPosition])

    const secondaryNavHeight = useMemo(() => {
        if (secondaryNavRef?.current) {
            return secondaryNavRef.current.offsetHeight;
        }
        return 0;
    },[secondaryNavRef?.current])

    return (
        <>
            <ul id={"skiptocontent"} className={styles.skip}>
                <li>
                    <a href={(interfaceStore.breakpoint === "break-point-lg") ? "#navigation" : "#MobileNavigationMenu"} tabIndex="2">Skip to Navigation</a>
                </li>
            </ul>
            <NavigationPrimary
                isWelcomePage={props.isWelcomePage}
                heroIsEnabled={props.displayHero}
                visible={visible}
            />
            {props.displayHero &&
                <CSSTransition in={props.displayHero} classNames={transitionClassNames} timeout={250}>
                    <div className={heroContainerClassName} ref={heroRef}>
                        <HeroContainer
                            handleViewHomepage={props.handleViewHomepage}
                            displayViewHomepageButton={props.displayViewHomepageButton}
                            allowEditing={props.allowEditing}
                            isWelcomePage={props.isWelcomePage}
                            secondaryNavHeight={secondaryNavHeight}
                        />
                    </div>
                </CSSTransition>}
            {/* Minimal Theme does not support vertical grids for now! */}
            {styleStore.themeID === "3" ||
                <span ref={secondaryNavRef}>
                    <NavigationSecondary
                        isWelcomePage={props.isWelcomePage}
                        visible={visible}
                        heroIsEnabled={props.displayHero}
                    />
                </span>}
        </>
    );
});

Header.propTypes = {
    displayHero: PropTypes.bool.isRequired, // displayHero is prop passed from config because not all pages have hero enabled
    displayViewHomepageButton: PropTypes.bool,
    displaySourcesWidget: PropTypes.bool,
    allowEditing: PropTypes.bool,
    currentFullUrl: PropTypes.string,
    isWelcomePage: PropTypes.bool,
    handleViewHomepage: PropTypes.func,
};

Header.defaultProps = {
    displayViewHomepageButton: false,
    displaySourcesWidget: false,
    allowEditing: false,
    isWelcomePage: false,
};

export default Header;
